import styled from "styled-components";
import CustomModal from "./CustomModal";
import { Button } from "antd";
import useCapiUrl from "../../hooks/useCapiUrl";
import parse from "html-react-parser";

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px 0 4px;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ProjectTitle = styled.h2`
  color: #fff;
  font-size: 24px;
  font-weight: 700;
`;

const IntroButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px 20px;
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const IntroButton = styled(Button)`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none; // Removes the default border
`;

const MainContentWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff;
  padding: 20px 0 20px;
  max-width: 1280px;
  width: 100%;

  & > p {
    font-size: 16px;
    padding-bottom: 20px;
  }

  & > ul,
  & > ol {
    list-style-position: inside;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;

    & > p {
      font-size: 14px;
    }
  }
`;

const ImageSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const PortfolioModal = ({
  open,
  onCancel = () => {},
  modalWidth = 356,
  data = {},
}) => {
  const url = useCapiUrl();
  return (
    <>
      <CustomModal
        maskClosable={true}
        closable={false}
        open={open}
        onCancel={onCancel}
        width={modalWidth}
      >
        <ContentWrapper>
          <TopWrapper>
            <ProjectTitle>{data?.projectTitle}</ProjectTitle>
            <IntroButtonContainer>
              <IntroButton
                type="primary"
                htmlType="submit"
                size="large"
                onClick={() => {
                  window.open(`${url}`, "_blank");
                }}
              >
                Learn More
              </IntroButton>
            </IntroButtonContainer>
          </TopWrapper>
          <MainContentWrapper>
            {data?.content && parse(data?.content)}
          </MainContentWrapper>
          <ImageSetWrapper>
            {data?.assets?.map((eachImage, index) => {
              return <Image src={eachImage} alt="Image" key={index} />;
            })}
          </ImageSetWrapper>
        </ContentWrapper>
      </CustomModal>
    </>
  );
};

export default PortfolioModal;
