import { CloseOutline } from "@styled-icons/evaicons-outline";
import { Modal } from "antd";
import styled from "styled-components";

const CustomModalStyled = styled(Modal)`
  & .ant-modal-content {
    background-color: #000e14;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const CustomModal = ({
  children = <></>,
  maskClosable = true,
  width = 500,
  closable = true,
  open = false,
  onCancel = () => {},
}) => {
  return (
    <CustomModalStyled
      destroyOnClose={true}
      maskClosable={maskClosable}
      closable={closable}
      centered={true}
      width={width}
      open={open}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        onCancel();
      }}
      // onClose={(e) => {
      //   e.stopPropagation();
      // }}
    >
      <IconWrapper
        onClick={(e) => {
          e.stopPropagation();
          onCancel();
        }}
      >
        <CloseOutline color="#fff" size={30} />
      </IconWrapper>
      {children}
    </CustomModalStyled>
  );
};

export default CustomModal;
