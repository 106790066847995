import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import useCapiUrl from "../../hooks/useCapiUrl";

const CtaSectionContainer = styled.div`
  width: 100%;
  padding: 60px 0;
  background: url("/assets/pattern.svg"), repeat #000e14;
  background-blend-mode: multiply;
`;

const CtaInnerContainer = styled.div`
  max-width: 961px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;

const CtaContentWrapper = styled.div`
  padding: 0 15px;
`;

const SatisfactionImage = styled.img`
  width: 150px;
`;

const BigTitle = styled.h2`
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.88px;
  letter-spacing: 0.9599999785423279px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: 0.9599999785423279px;
  }
`;

const SmallTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #fff;
  padding-top: 25px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 27.2px;
  }
`;

const IntroButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px 20px;
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const IntroButton = styled(Button)`
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none; // Removes the default border

  @media only screen and (max-width: 600px) {
    width: 200px;
  }

  @media only screen and (max-width: 355px) {
    width: 200px;
  }
`;

const Cta = ({ bigTitle, smallTitle, buttonText }) => {
  const url = useCapiUrl();

  return (
    <CtaSectionContainer>
      <CtaInnerContainer>
        <CtaContentWrapper>
          <SatisfactionImage
            src="/assets/satisfaction.png"
            alt="Satisfaction"
          />
          <BigTitle>{bigTitle}</BigTitle>
          <SmallTitle>{smallTitle}</SmallTitle>
          <IntroButtonContainer>
            <IntroButton
              type="primary"
              htmlType="submit"
              size="large"
              onClick={() => {
                window.open(url, "_blank");
              }}
            >
              {buttonText}
            </IntroButton>
          </IntroButtonContainer>
        </CtaContentWrapper>
      </CtaInnerContainer>
    </CtaSectionContainer>
  );
};

export default Cta;
