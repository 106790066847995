/**
 * Trims the input string to a specified length and adds an ellipsis if necessary.
 *
 * @param {string} text - The string to be trimmed.
 * @param {number} maxLength - The maximum number of characters before adding an ellipsis.
 * @returns {string} - The trimmed string with an ellipsis if it exceeds the specified length.
 */
export const trimText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};
