import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";
import { Button, Tag } from "antd";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";

const PricingSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const PricingSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const PricingSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const PricingContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  padding: 60px 0 0;

  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const EachPlanWrapper = styled.div`
  cursor: pointer;
  flex: 0 0 30%;
  width: 30%;
  border-radius: 32px;
  border: ${(props) =>
    props.recommended
      ? "1px solid rgba(15, 212, 251, 1)"
      : "1px solid transparent"};
  background: ${(props) =>
    props.recommended
      ? "-moz-linear-gradient(135.37deg, rgba(9, 126, 149, 0.3) 3.85%, rgba(15, 212, 251, 0.3) 57.47%, rgba(9, 126, 149, 0.3) 92.73%)"
      : "#012028"};
  background: ${(props) =>
    props.recommended
      ? "-webkit-linear-gradient(135.37deg, rgba(9, 126, 149, 0.3) 3.85%, rgba(15, 212, 251, 0.3) 57.47%, rgba(9, 126, 149, 0.3) 92.73%)"
      : "#012028"};
  background: ${(props) =>
    props.recommended
      ? "linear-gradient(135.37deg, rgba(9, 126, 149, 0.3) 3.85%, rgba(15, 212, 251, 0.3) 57.47%, rgba(9, 126, 149, 0.3) 92.73%)"
      : "#012028"};
  height: 790px;

  @media only screen and (max-width: 1200px) {
    height: 900px;
  }

  @media only screen and (max-width: 1023px) {
    flex: 0 0 50%;
    width: 50%;
  }

  @media only screen and (max-width: 760px) {
    flex: 0 0 70%;
    width: 70%;
  }

  @media only screen and (max-width: 599px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const EachPlanContentWrapper = styled.div`
  padding: 30px 25px 80px;
  text-align: left;
`;

const PlanName = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  color: #fff;
  padding-bottom: 10px;
`;

const Price = styled.h2`
  font-size: 36px;
  font-weight: 600;
  line-height: 50.4px;
  color: #fff;

  @media only screen and (max-width: 600px) {
    font-size: 36px;
    font-weight: 600;
    line-height: 50.4px;
  }
`;

const Duration = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  color: #f5efef;
`;

const Desc = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #fff;
  padding: 15px 0;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FeaturesContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const CheckIcon = styled.img`
  width: 20px;
  aspect-ratio: 1;
`;

const FeatureText = styled.p`
  color: #f5efef;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
`;

const RecommendedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;
  padding-bottom: 20px;
`;

const RecommendedWrapper = styled.div`
  max-width: 200px;
  width: 100%;

  & .ant-tag {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4px 0;
    border-radius: 24px;
    background: rgba(9, 126, 149, 0.4);
    color: rgba(15, 212, 251, 1);
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    border: none;
  }
`;

const IntroButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px 20px;
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const IntroButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none; // Removes the default border
  margin: 20px 0;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (max-width: 355px) {
    width: 100%;
  }
`;

const ViewPricingContainer = styled.div`
  display: flex;
  justify-content: center;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px;
    background: transparent !important;
    border: 2px solid #0fd4fb;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const ViewPricingText = styled.span`
  background-color: #fff;
  background-image: linear-gradient(
    86.56deg,
    #0fd4fb -2.06%,
    #2ba3ba 51.2%,
    #0fd4fb 97.16%
  );
  background-image: conic-gradient(
    86.56deg,
    #0fd4fb -2.06%,
    #2ba3ba 51.2%,
    #0fd4fb 97.16%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;

const ExtraTextWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  padding: 15px 0;
`;

const SectionDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff;
  padding: 20px 0 40px;
  max-width: 1280px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
  }
`;

const IncludedText = styled.p`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  padding: 20px 0;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

const PricingSection = () => {
  const navigate = useNavigate();
  const EachPlanCmp = ({ data }) => {
    return (
      <EachPlanWrapper
        recommended={data.recommended}
        onClick={() => {
          if (!data?.paymentLink) {
            navigate("/contact");
          } else {
            window.open(data?.paymentLink, "_blank");
          }
        }}
      >
        <EachPlanContentWrapper>
          <RecommendedContainer>
            <RecommendedWrapper>
              {data.recommended && <Tag>Popular - Save 15%</Tag>}
            </RecommendedWrapper>
          </RecommendedContainer>

          <PlanName recommended={data.recommended}>{data.name}</PlanName>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Price>{data.price}</Price>
            {data.price !== "Custom" && (
              <>
                <Duration>
                  {data?.name === "Quarterly" ? "/3 months" : "/month"}
                </Duration>
              </>
            )}
          </div>
          <Desc>{data.desc}</Desc>
          {data.recommended ? (
            <IntroButtonContainer>
              <IntroButton type="primary" htmlType="submit" size="large">
                {data.btnText}
              </IntroButton>
            </IntroButtonContainer>
          ) : (
            <ViewPricingContainer>
              <IntroButton type="primary" htmlType="submit" size="large">
                <ViewPricingText>{data.btnText}</ViewPricingText>
              </IntroButton>
            </ViewPricingContainer>
          )}
          <IncludedText>What's Included?</IncludedText>
          <FeaturesWrapper>
            {data?.features?.map((v, i) => {
              return (
                <FeaturesContentWrapper key={i}>
                  <CheckIcon src={"/assets/tick.svg"} />
                  <FeatureText>{v}</FeatureText>
                </FeaturesContentWrapper>
              );
            })}
          </FeaturesWrapper>
          {data?.extraText && (
            <ExtraTextWrapper>{data?.extraText}</ExtraTextWrapper>
          )}
        </EachPlanContentWrapper>
      </EachPlanWrapper>
    );
  };
  return (
    <Element name="pricing">
      <PricingSectionContainer>
        <PricingSectionInnerContainer>
          <PricingSectionContentWrapper>
            <CustomDoubleTitle
              firstTitle={"PRICING"}
              secondTitle={"Your Investment"}
            />
            <SectionDesc>
              All-in-one development, design, and project management—less than
              hiring multiple in-house specialists.
            </SectionDesc>

            <PricingContainerWrapper>
              {[
                {
                  name: "Standard",
                  price: "$6,900",
                  desc: "Billed monthly",
                  features: [
                    "One Developer",
                    "One Project Manager",
                    "One Designer",
                    "Get complete software services (AI Integration, AI development, API Integrations)",
                  ],
                  recommended: false,
                  paymentLink: "https://buy.stripe.com/3cs7tK5q23lG3YY14j",
                  btnText: "Get Started →",
                  extraText:
                    "9 hours a day. Ideal for ongoing projects requiring continuous development and growth.",
                },
                {
                  name: "Quarterly",
                  price: "$19,200",
                  desc: "Enjoy a 15% savings by committing to three months upfront",
                  features: [
                    "One Developer",
                    "One Project Manager",
                    "One Designer",
                    "Get complete software services (AI Integration, AI development, API Integrations)",
                  ],
                  recommended: true,
                  paymentLink: "https://buy.stripe.com/28o9BS8CecWgeDCcNJ",
                  btnText: "Get Started →",
                  extraText:
                    "9 hours a day. Perfect for businesses looking for a longer-term partnership with consistent results and strategic planning.",
                },
                {
                  name: "Ad-hoc",
                  price: "Custom",
                  desc: "Contact our sales team to design a plan that fits your specific goals and requirements.",
                  features: [
                    "45-minute project consultation",
                    "Documentation of every project",
                    "Tailored solutions for one-off projects or specialized needs",
                  ],
                  recommended: false,
                  paymentLink: "",
                  btnText: "Contact Sales →",
                },
              ].map((v, i) => {
                return <EachPlanCmp key={i} data={v} />;
              })}
            </PricingContainerWrapper>
          </PricingSectionContentWrapper>
        </PricingSectionInnerContainer>
      </PricingSectionContainer>
    </Element>
  );
};

export default PricingSection;
