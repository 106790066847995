import { useState, useEffect } from "react";

const useCapiUrl = () => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const getCookieValue = (name) => {
      const cookieName = `${name}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }
      return "";
    };

    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return "";
      }
    };

    const fetchData = async () => {
      const ipAddress = await fetchIpAddress();
      const userAgent = navigator.userAgent;
      const fbcValue = getCookieValue("_fbc");

      const capiUrl = `https://calendly.com/stas-sorokin/project-development-meeting-with-stas-sorokin-clone?utm_medium=${ipAddress}&utm_source=${userAgent}&utm_campaign=${fbcValue}`;

      setUrl(capiUrl);
    };

    fetchData();
  }, []);

  return url;
};

export default useCapiUrl;
