import React, { useState } from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";
import { Element } from "react-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import {
  ArrowIosBackOutline,
  ArrowIosForwardOutline,
} from "@styled-icons/evaicons-outline";
import { portfolioData } from "../../data/portfolioData";
import { trimText } from "../../utils";
import PortfolioModal from "../Modal/PortfolioModal";

const SubscriptionStepSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const SubscriptionStepSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const SubscriptionStepSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const EachPortfolioWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid #0b3e40;
  padding: 20px 16px;
  cursor: pointer;
`;

const EachPortfolioInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const EachPortfolioImage = styled.img`
  width: 100%;
  aspect-ratio: 395/296;
  object-fit: cover;
  border-radius: 8px;
`;

const EachPortfolioTitle = styled.h2`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  height: 90px;
`;

const EachPortfolioDesc = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #fff;
`;

const PortfolioWrapper = styled.div`
  padding: 50px 0 10px;
  position: relative;

  & > .arrow {
    position: absolute;
    top: 50%;
    margin-top: -5px;
    cursor: pointer;

    @media only screen and (max-width: 650px) {
      display: none;
    }
  }

  & > .arrow.arrow-left,
  & > .arrow.arrow-left-2 {
    left: 10px;

    @media only screen and (max-width: 844px) {
      left: 0px;
    }
  }
  & > .arrow.arrow-right,
  & > .arrow.arrow-right-2 {
    right: 10px;

    @media only screen and (max-width: 844px) {
      right: 0px;
    }
  }
`;

const ArrowImgWrapper = styled.div`
  z-index: 300;
`;

const PortfolioSection = () => {
  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
  const [activeData, setActiveData] = useState({});
  const EachPortfolioCmp = ({ data }) => {
    return (
      <EachPortfolioWrapper>
        <EachPortfolioInnerWrapper>
          <EachPortfolioImage src={data?.assets[0]} alt="Portfolio" />
          <EachPortfolioTitle>{data?.projectTitle}</EachPortfolioTitle>
          <EachPortfolioDesc>{trimText(data?.snippet, 80)}</EachPortfolioDesc>
        </EachPortfolioInnerWrapper>
      </EachPortfolioWrapper>
    );
  };
  return (
    <Element name="how-it-works">
      <SubscriptionStepSectionContainer>
        <SubscriptionStepSectionInnerContainer>
          <SubscriptionStepSectionContentWrapper>
            <CustomDoubleTitle
              firstTitle={"PORTFOLIO"}
              secondTitle={"Check our works"}
            />

            <PortfolioWrapper>
              <Swiper
                className="portfolio-carousel"
                modules={[Autoplay, Pagination, Navigation]}
                loop={true}
                grabCursor={true}
                watchSlidesVisibility={true}
                // slidesPerView={4}
                breakpoints={{
                  // when window width is >= 320px
                  300: { slidesPerView: 1 },
                  480: { slidesPerView: 1.2 },
                  600: { slidesPerView: 1.6 },
                  750: { slidesPerView: 2 },
                  850: { slidesPerView: 2.3 },
                  1010: { slidesPerView: 2.4 },
                  1100: { slidesPerView: 2.5 },
                  1200: { slidesPerView: 3 },
                  1350: { slidesPerView: 4 },
                }}
                autoplay={{
                  delay: 6000,
                }}
                pagination={{
                  clickable: true,
                  el: ".portfolio-pagination",
                }}
                navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                speed={1500}
                style={{ width: "100%" }}
                spaceBetween={10}
              >
                {portfolioData.slice(0, 14)?.map((v, i) => {
                  return (
                    <SwiperSlide
                      key={i}
                      style={{ width: "100%" }}
                      onClick={() => {
                        setActiveData(v);
                        setOpenPortfolioModal(true);
                      }}
                    >
                      <EachPortfolioCmp data={v} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <ArrowImgWrapper className="arrow-left arrow">
                <ArrowIosBackOutline size={50} color="#AEABAB" />
              </ArrowImgWrapper>

              <ArrowImgWrapper className="arrow-right arrow">
                <ArrowIosForwardOutline size={50} color="#AEABAB" />
              </ArrowImgWrapper>
            </PortfolioWrapper>
            <PortfolioWrapper>
              <Swiper
                className="portfolio-carousel"
                modules={[Autoplay, Pagination, Navigation]}
                loop={true}
                grabCursor={true}
                watchSlidesVisibility={true}
                // slidesPerView={4}
                breakpoints={{
                  // when window width is >= 320px
                  300: { slidesPerView: 1 },
                  480: { slidesPerView: 1.2 },
                  600: { slidesPerView: 1.6 },
                  750: { slidesPerView: 2 },
                  850: { slidesPerView: 2.3 },
                  1010: { slidesPerView: 2.4 },
                  1100: { slidesPerView: 2.5 },
                  1200: { slidesPerView: 3 },
                  1350: { slidesPerView: 4 },
                }}
                autoplay={{
                  delay: 5000,
                }}
                pagination={{
                  clickable: true,
                  el: ".portfolio-pagination",
                }}
                navigation={{
                  nextEl: ".arrow-right-2",
                  prevEl: ".arrow-left-2",
                }}
                speed={1500}
                style={{ width: "100%" }}
                spaceBetween={10}
              >
                {portfolioData.slice(14)?.map((v, i) => {
                  return (
                    <SwiperSlide
                      key={i}
                      style={{ width: "100%" }}
                      onClick={() => {
                        setActiveData(v);
                        setOpenPortfolioModal(true);
                      }}
                    >
                      <EachPortfolioCmp data={v} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <ArrowImgWrapper className="arrow-left-2 arrow">
                <ArrowIosBackOutline size={50} color="#AEABAB" />
              </ArrowImgWrapper>

              <ArrowImgWrapper className="arrow-right-2 arrow">
                <ArrowIosForwardOutline size={50} color="#AEABAB" />
              </ArrowImgWrapper>
            </PortfolioWrapper>
          </SubscriptionStepSectionContentWrapper>
        </SubscriptionStepSectionInnerContainer>
      </SubscriptionStepSectionContainer>
      <PortfolioModal
        open={openPortfolioModal}
        onCancel={() => {
          setOpenPortfolioModal(false);
        }}
        modalWidth={900}
        data={activeData}
      />
    </Element>
  );
};

export default PortfolioSection;
