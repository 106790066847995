import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";
import { Table } from "antd";

const CompareSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const CompareSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const CompareSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const CompareTableWrapper = styled.div`
  padding: 40px 0;

  & .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #000e14;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    border-bottom: 1px solid #0b3e40;
    padding: 30px 16px;
  }

  & .ant-table-wrapper .ant-table-tbody > tr > td {
    background-color: #000e14;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    width: fit-content;
    border-bottom: 1px solid #0b3e40;
    padding: 30px 16px;
  }

  & .ant-table-wrapper .ant-table-tbody > tr > td:not(:first-child) {
    font-size: 12px;
    line-height: 16px;
  }

  & .ant-table-cell-row-hover {
    background-color: #000e14 !important;
  }

  & .ant-table-content {
    -ms-overflow-style: none !important; /* Internet Explorer 10+ */
    scrollbar-width: none !important; /* Firefox */

    & .ant-table-content::-webkit-scrollbar {
      display: none !important; /* Safari and Chrome */
      width: 0px !important;
      background: transparent !important; /* make scrollbar transparent */
    }
  }

  &
    .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
`;

const TextTableSpan = styled.span`
  padding: 3px 8px;
  border-radius: 4px;
  background-color: #062223;
`;

const SectionDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff;
  padding: 20px 0 40px;
  max-width: 1280px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
  }
`;

const CompareSection = () => {
  return (
    <CompareSectionContainer>
      <CompareSectionInnerContainer>
        <CompareSectionContentWrapper>
          <CustomDoubleTitle
            firstTitle={"COMPARISON"}
            secondTitle={"Why Choose Us?"}
          />
          <SectionDesc>
            Get an expert team of a project manager, designer, and developer on
            a flexible plan that delivers fast, high-quality results without the
            long-term commitment or high costs of traditional options.
          </SectionDesc>

          <CompareTableWrapper>
            <Table
              pagination={false}
              scroll={{ x: "max-content" }}
              columns={[
                {
                  title: "",
                  dataIndex: "feature",
                  key: "feature",
                  width: "125px",
                },
                {
                  title: "FREELANCE ❌",
                  dataIndex: "freelance",
                  key: "freelance",
                  width: "150px",
                  render: (value) => {
                    return <TextTableSpan>{value}</TextTableSpan>;
                  },
                },
                {
                  title: "IN-HOUSE ❌",
                  dataIndex: "inhouse",
                  key: "inhouse",
                  width: "150px",
                  render: (value) => {
                    return <TextTableSpan>{value}</TextTableSpan>;
                  },
                },
                {
                  title: "AGENCY ❌",
                  dataIndex: "agency",
                  key: "agency",
                  width: "150px",
                  render: (value) => {
                    return <TextTableSpan>{value}</TextTableSpan>;
                  },
                },
                {
                  title: "BLES SOFTWARE ✅",
                  dataIndex: "company",
                  key: "company",
                  width: "180px",
                  render: (value) => {
                    return <TextTableSpan>{value}</TextTableSpan>;
                  },
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  feature: "Monthly cost",
                  freelance: "$7,000+",
                  inhouse: "$10,000+",
                  agency: "$7,000+ and unpredictable timelines",
                  company:
                    "$6,900/month, start in a few days, flexible and fully dedicated team",
                },
                {
                  key: "2",
                  feature: "Time to hire",
                  freelance: "Days/weeks",
                  inhouse: "Weeks/months",
                  agency: "Weeks/months",
                  company: "Hire in hours",
                },
                {
                  key: "3",
                  feature: "Skill level",
                  freelance: "Novice or junior",
                  inhouse: "Varies",
                  agency: "Unpredictable",
                  company: "Expert team",
                },
                {
                  key: "4",
                  feature: "First delivery",
                  freelance: "Several weeks",
                  inhouse: "Several weeks",
                  agency: "Several weeks",
                  company: "Deliver in 2-3 days",
                },
                {
                  key: "5",
                  feature: "Significant",
                  freelance: "High Costs + Uncertainty",
                  inhouse: "High Overhead + Hassle",
                  agency: "Long Contracts + Uncertainty",
                  company: "Flexible, hassle-free",
                },
              ]}
            />
          </CompareTableWrapper>
        </CompareSectionContentWrapper>
      </CompareSectionInnerContainer>
    </CompareSectionContainer>
  );
};

export default CompareSection;
