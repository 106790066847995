import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";
import { Button, Collapse } from "antd";
import useCapiUrl from "../../hooks/useCapiUrl";

const FaqSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const FaqSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const FaqSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const CollapseWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 30px;

  & .ant-collapse {
    width: 100%;
  }

  & .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #012534cc;
  }

  & .ant-collapse-header {
    background-color: transparent;
    color: #fff !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
  }

  & .ant-collapse-header-text {
    text-align: left;
  }

  & .ant-collapse-item-active {
    background-color: #012534;
  }

  & .ant-collapse-item.ant-collapse-item-active {
    border-bottom: none;
  }

  & .ant-collapse-item-active > .ant-collapse-header {
    color: #0fd4fb !important;
  }

  & .ant-collapse-content-box {
    padding: 16px !important;
  }

  @media only screen and (max-width: 600px) {
    background-color: transparent;
    color: #fff !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;
  }
`;

const CollapseInnerContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #d9d9d9;
  text-align: left;
`;

const SmallTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #fff;
  padding-top: 25px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 27.2px;
  }
`;

const IntroButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px 20px;
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const IntroButton = styled(Button)`
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none; // Removes the default border

  @media only screen and (max-width: 600px) {
    width: 200px;
  }

  @media only screen and (max-width: 355px) {
    width: 200px;
  }
`;

const FaqSection = () => {
  const url = useCapiUrl();
  return (
    <FaqSectionContainer>
      <FaqSectionInnerContainer>
        <FaqSectionContentWrapper>
          <CustomDoubleTitle
            firstTitle={"FREQUENTLY ASKED QUESTIONS"}
            secondTitle={"FAQ"}
          />

          <CollapseWrapper>
            <Collapse
              bordered={false}
              expandIconPosition="end"
              size="large"
              accordion
              items={[
                {
                  title: "What services does Bles Software provide?",
                  content:
                    "We offer an expert-level team consisting of a project manager, designer, and developer who work 9 hours a day to build and manage AI-powered growth systems for B2B companies.",
                },
                {
                  title: "How quickly can I get started with Bles Software?",
                  content:
                    "You can get started within a few hours. Once you choose your plan, our team is ready to begin working on your project immediately.",
                },
                {
                  title: "How long does it take to see results?",
                  content:
                    "Most clients start seeing tangible results, such as increased efficiency and improved sales, within 2-3 days of implementation.",
                },
                {
                  title: "How does payment work?",
                  content:
                    "When you click one of the payment options above, you'll be taken to a Stripe Checkout page. After you pay, we'll automatically create a subscription for you that renews on the same day every month. You can cancel or pause at any time.",
                },
                {
                  title: "What is the cost of your service?",
                  content:
                    "Our services start at $6,900 per month, with options for quarterly plans at $23,600 or custom plans for one-off projects. Contact us for more details.",
                },
                {
                  title:
                    "Can your systems be customized to fit my specific business needs?",
                  content:
                    "Absolutely. We tailor our AI-driven systems to meet the unique requirements of your business, ensuring optimal performance and growth.",
                },
                {
                  title: "How do I submit a request?",
                  content:
                    "After your thirty minute onboarding call, you'll receive access to your own ClickUp board, where you can view and manage ongoing projects. To create a request, you merely click the + button on your board and add the task to our queue directly.",
                },
                {
                  title: "How do we communicate?",
                  content:
                    "Vast majority of our comms are async, through Slack. Minimizing call-time provides much more flexibility, allowing us to deliver more projects and higher quality services. That said, we offer an optional weekly ops consultation to discuss bottlenecks, prioritize, and track progress.",
                },
              ].map((v, i) => {
                return {
                  key: `${i}`,
                  label: v.title,
                  children: (
                    <CollapseInnerContent>{v.content}</CollapseInnerContent>
                  ),
                };
              })}
              defaultActiveKey={[0]}
            />
          </CollapseWrapper>

          <SmallTitle>Still have questions? </SmallTitle>
          <IntroButtonContainer>
            <IntroButton
              type="primary"
              htmlType="submit"
              size="large"
              onClick={() => {
                window.open(url, "_blank");
              }}
            >
              Schedule a free call
            </IntroButton>
          </IntroButtonContainer>
        </FaqSectionContentWrapper>
      </FaqSectionInnerContainer>
    </FaqSectionContainer>
  );
};

export default FaqSection;
